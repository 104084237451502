import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "default" */ '@/views/Login'),
        meta: {
            title: 'Login',
            guard: false
        }
    },
    {
        path: '/impersonate',
        name: 'Impersonate',
        component: () => import(/* webpackChunkName: "default" */ '@/views/Login'),
        meta: {
            title: 'Impersonate',
            guard: false
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "default" */ '@/views/Home'),
        meta: {
            title: 'Home',
            guard: false
        }
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "default" */ '@/views/Dashboard'),
        meta: {
            title: 'Dashboard',
            guard: false
        }
    },
    {
        path: '/applications',
        component: () => import(/* webpackChunkName: "default" */ '@/views/partials/SubRoot'),
        children: [
            {
                path: 'forms',
                name: 'Application Forms',
                component: () => import(/* webpackChunkName: "applications" */ '@/views/applications/Forms'),
                meta: {
                    title: 'Application Forms',
                    guard: false
                }
            },
            {
                path: 'new/:id/:member',
                name: 'New Application',
                component: () => import(/* webpackChunkName: "applications" */ '@/views/applications/Form'),
                meta: {
                    title: 'New Application',
                    guard: false
                },
                props: (route) => ({
                    formID: route.params.id,
                    memberID: route.params.member
                })
            },
            {
                path: 'edit/:id/:member/:submission',
                name: 'Continue Application',
                component: () => import(/* webpackChunkName: "applications" */ '@/views/applications/Form'),
                meta: {
                    title: 'Continue Application',
                    guard: false
                },
                props: (route) => ({
                    formID: route.params.id,
                    memberID: route.params.member,
                    submissionID: route.params.submission
                })
            },
            {
                path: 'submissions',
                name: 'Submissions',
                component: () => import(/* webpackChunkName: "applications" */ '@/views/applications/Submissions'),
                meta: {
                    title: 'Submissions',
                    guard: false
                }
            }
        ]
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "default" */ '@/views/Profile'),
        meta: {
            title: 'Profile',
            guard: false
        }
    },
    {
        path: '/news',
        name: 'News',
        component: () => import(/* webpackChunkName: "default" */ '@/views/News'),
        meta: {
            title: 'News',
            guard: false
        }
    },
    {
        path: '/news/:id',
        name: 'View News',
        component: () => import(/* webpackChunkName: "default" */ '@/views/NewsItem'),
        meta: {
            title: 'News Item',
            guard: false
        },
        props: (route) => ({
            id: route.params.id
        })
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
