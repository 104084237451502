import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import axios from 'axios'
import DataModule from './modules/data'

const vuexLocal = new VuexPersistence({
    key: 'fasperthpwa',
    storage: window.localStorage
})

export default createStore({
    state: {
        token: '',
        user: {
            relationships: []
        },
        isMenuOpen: false
    },
    mutations: {
        AUTH_SUCCESS: (state, payload) => {
            if (payload.token) {
                state.token = payload.token
            }

            state.user = payload.user
        },
        CLEAR_SESSION: (state) => {
            state.token = ''
            state.user = {
                relationships: []
            }
        },
        TOGGLE_MENU: (state, payload) => {
            if (payload === false || payload === true) {
                state.isMenuOpen = payload
            } else {
                state.isMenuOpen = !state.isMenuOpen
            }
        }
    },
    actions: {
        SET_AUTH: ({ commit }, payload) => {
            return new Promise((resolve) => {
                commit('AUTH_SUCCESS', payload)
                resolve(payload)
            })
        },
        AUTH_REQUEST: ({ commit }, user) => {
            return new Promise((resolve, reject) => { // The Promise used for router redirect in login
                axios.post(process.env.VUE_APP_API_URL + '/public/login', user).then(response => {
                    axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token
                    commit('AUTH_SUCCESS', response.data)
                    resolve(response)
                }).catch(error => {
                    // commit('CLEAR_SESSION')
                    // commit('CLEAR_CACHE')
                    reject(error)
                })
            })
        },
        GET_ME: ({ commit, state, dispatch }) => {
            if (state.token) {
                return new Promise((resolve, reject) => {
                    axios.get(process.env.VUE_APP_API_URL + '/public/me').then(response => {
                        commit('AUTH_SUCCESS', response.data)
                        resolve(response)
                    }).catch(error => {
                        // commit('CLEAR_SESSION')
                        // commit('CLEAR_CACHE')
                        reject(error)
                    })
                })
            }
        },
        AUTH_LOGOUT: ({ commit }) => {
            return new Promise((resolve) => {
                commit('CLEAR_SESSION')
                // commit('CLEAR_CACHE')
                delete axios.defaults.headers.common.Authorization
                resolve()
            })
        },
        TOGGLE_MENU: ({ commit }, payload) => {
            commit('TOGGLE_MENU', payload)
        }
    },
    getters: {
        isMenuOpen: state => {
            return state.isMenuOpen
        },
        user: state => {
            return state.user
        },
        token: state => {
            return state.token
        }
    },
    modules: {
        data: DataModule
    },
    plugins: [vuexLocal.plugin]
})
