import axios from 'axios'

const state = {
    forms: []
}

const actions = {
    GET_FORMS ({ commit }, memberID) {
        return new Promise((resolve, reject) => { // The Promise used for router redirect in login
            axios.get(process.env.VUE_APP_API_URL + '/public/forms', {
                params: {
                    member: memberID
                }
            }).then(response => {
                commit('SET_FORMS', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

const mutations = {
    SET_FORMS (state, payload) {
        state.forms = payload
    }
}

const getters = {
    forms: state => {
        return state.forms
    }
}

const module = {
    state,
    mutations,
    getters,
    actions
}

export default module
