import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/css/index.css'
import axios from 'axios'
import { Loader } from '@googlemaps/js-api-loader'

import Maska from 'maska'

router.beforeEach((to, from, next) => {
    if (to.name === 'Impersonate') {
        const urlSearchParams = new URLSearchParams(window.location.search)

        if (urlSearchParams.has('code')) {
            axios.post(process.env.VUE_APP_API_URL + '/public/impersonate', {
                code: urlSearchParams.get('code')
            }).then(response => {
                store.dispatch('SET_AUTH', response.data).then(() => {
                    router.push('/dashboard')
                })
            })
        }
    }

    store.dispatch('TOGGLE_MENU', false)

    document.title = to.meta.title + ' | ' + process.env.VUE_APP_NAME

    if (to.meta.public) {
        next()
    } else {
        if (to.name === 'reset-password') {
            next()
        } else if (store.getters.token === '' && to.name !== 'Login') {
            next('login')
        } else if (store.getters.token !== '' && to.name === 'Login') {
            next('home')
        } else if (store.getters.token !== '' && to.path === '/') {
            next('home')
        } else if (store.getters.token === '' && to.path === '/') {
            next('login')
        } else {
            next()
        }
    }
})

// Handle HTTP Requests
axios.interceptors.request.use((config) => {
    if (!router.currentRoute.meta || !router.currentRoute.meta.public) {
        config.headers.common.Authorization = 'Bearer ' + JSON.parse(localStorage.getItem('fasperthpwa')).token
    }
    return config
}, function (error) {
    return Promise.reject(error)
})

// Handle HTTP Responses
axios.interceptors.response.use(response => {
    return response
}, function (error) {
    if (error.response.status === 401 || error.response.data.message === 'Unauthenticated.') {
        store.dispatch('AUTH_LOGOUT').then(() => router.push('login'))
    } else {
        return Promise.reject(error)
    }
})

window.loader = new Loader({
    apiKey: 'AIzaSyADJ7Lt2v6r5zyX0FHSOmvyPSLYotTfG-Y',
    version: '3.40',
    libraries: ['geometry', 'places'],
    region: 'AU'
})

const app = createApp(App)

app.use(store)
app.use(router)
app.use(Maska)
app.mount('#app')
